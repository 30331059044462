.dashboard {
  margin-top: 50px;
  margin-bottom: 40px;
  .banner-slider {
    overflow: hidden;
    .slick-slide {
      figure {
        margin-bottom: 0px;
        border-radius: 5px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .slick-dots {
      position: absolute;
      bottom: 25px;
      text-align: left;
      left: 35px;
      li {
        button {
          width: 10px;
          height: 10px;
          border: 1px solid #fff;
          background: none;
          border-radius: 100%;
          &:before {
            display: none;
          }
        }
        &.slick-active {
          button {
            width: 15px;
            height: 15px;
            background: var(--bs-primary-color);
            border-color: var(--bs-primary-color);
          }
        }
      }
    }
  }
  .table {
    tr {
      td {
        padding: 12px 0px;
        vertical-align: top;
      }
      td:first-child {
        font-size: 1.4rem;
        color: #9e9e9e;
      }
      td:last-child {
        font-size: 1.4rem;
        font-weight: bold;
      }
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      &:last-child {
        border-bottom: 0px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .dashboard {
    margin-top: 30px;
    margin-bottom: 30px;
    .banner-slider {
      margin-top: 30px;
    }
  }
}
