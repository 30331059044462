.static-pages {
  padding-top: 55px;
  padding-bottom: 55px;

  .page-intro-content {
    max-width: 1020px;
    margin: 0px auto;
  }

  .page-title {
    font-size: 3.4rem;
    font-weight: 600;
    margin-bottom: 40px;
    background: transparent;
    padding: 0px;

    span {
      position: relative;
      padding-bottom: 30px;
      display: inline-block;

      &:after {
        content: "";
        height: 4px;
        width: 60px;
        background: var(--bs-yellow);
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        margin: auto;
      }
    }
  }

  p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 25px;

    &.x-large {
      font-size: 2rem;
    }

    &.large {
      font-size: 1.8rem;
    }
  }

  .img-fw {
    width: 100%;
    height: auto;
  }
}

// team section css start

.team-section {
  .section-tittle {
    text-align: center;
    font-family: "Ride";
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 50px;
    position: relative;
    margin: 0px;
    margin-bottom: 63px;

    &::after {
      position: absolute;
      content: "";
      width: 60px;
      bottom: 0px;
      height: 4px;
      background: #ffcc00;
      left: 50%;
      transform: translate(-50%, 19px);
    }
  }

  .inner-section {
    display: flex;
    max-width: 1440px;
    margin-inline: auto;
    padding: 30px 15px;
    column-gap: 20px;

    .person-box {
      width: 25%;
      background: #f2f4f7;

      img {
        width: 100%;
        height: 100%;
      }

      .team-img-box {
        display: flex;
      }

      .person-detail {
        background: #f2f4f7;
        border-top: 3px solid #ffd100;
        padding: 17px 20px;

        h3,
        p {
          margin: 0px;
          color: #222222;
        }

        .tittle-name {
          font-weight: 600;
          font-size: 22px;
          line-height: 32px;
        }

        .tittle-role {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }

        .tittle-contact-box {
          display: flex;
          flex-direction: column;
          // row-gap: 7px;

          a {
            text-decoration: none;
            color: black;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
          }

          a img {
            width: 15px !important;
            margin-right: 7px;
          }
        }
      }
    }
  }
}

.operation-extra-section {
  padding: 15px;

  .section-tittle {
    text-align: center;
    font-family: "Ride";
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 50px;
    position: relative;
    margin: 0px;
    margin-bottom: 63px;

    &::after {
      position: absolute;
      content: "";
      width: 60px;
      bottom: 0px;
      height: 4px;
      background: #ffcc00;
      left: 50%;
      transform: translate(-50%, 19px);
    }
  }

  section.extra-equip-section {
    max-width: 1210px;
    margin-inline: auto;
  }

  .extra-equip-inner {
    display: flex;
    align-items: center;
    column-gap: 100px;

    h3,
    p {
      margin: 0px;
    }

    .extra-equip-img.extra-equip-box img {
      width: 100%;
    }

    .extra-equip-box {
      flex: 1;
      width: 100%;
    }

    .tittle-name {
      font-weight: 600;
      font-size: 22px;
      line-height: 32px;
      color: #222222;
    }

    .extra-msg-txt {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #222222;
      /* padding-right: 190px; */
      max-width: 437px;
      margin-top: 15px;
    }
  }

  .operation-accordian-section {
    max-width: 1210px;
    margin-inline: auto;
    padding-top: 40px;

    .operation-accordian-inner {
      // padding: 15px;

      .accordian-box {
        margin-bottom: 25px;
        .acoordian-head {
          background: #ffcc00;
          padding: 10px 20px;
          // margin-bottom: 25px;
          position: relative;

          .sign-box {
            position: absolute;
            top: 16px;
            right: 20px;
          }

          img.close-sign {
            display: none;
          }
        }

        .tittle-name {
          font-weight: 600;
          font-size: 22px;
          line-height: 32px;
          color: #222222;
          margin: 0px;
        }

        .accordian-content {
          display: none;
          font-size: 1.6rem;
          //  padding: 15px 20px;

          ul {
            margin: 0px;
            padding: 0px;
            padding: 15px 20px;

            li {
              list-style: none;
              padding: 10px 0px;
              line-height: 24px;
              color: #222222;
            }
          }
        }
      }
    }
  }
}

section.customer-srvc-section {
  max-width: 1440px;
  margin-inline: auto;
  padding: 15px 40px;

  .customer-srvc-inner {
    display: flex;
    align-items: center;
    column-gap: 70px;
    row-gap: 10px;

    .tittle-name {
      // text-align: center;
      font-family: "Ride";
      font-style: normal;
      font-weight: 600;
      font-size: 34px;
      line-height: 50px;
      position: relative;
      margin: 0px;
      margin-bottom: 63px;

      &::after {
        position: absolute;
        content: "";
        width: 60px;
        bottom: 0px;
        height: 4px;
        background: #ffcc00;
        left: 0;
        transform: translateY(19px);
      }
    }
  }

  .extra-equip-box {
    width: 100%;
    flex: 1;

    p.extra-msg-txt {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #222222;

      a {
        font-size: 20px;
      }
    }

    img {
      // width: 100%;
    }
  }
}

.cmn-section {
  max-width: 1130px;
  margin-inline: auto;
  margin-bottom: 70px;
}

.rate-condtn-wrapper {
  padding: 15px;

  .section-tittle {
    text-align: center;
    font-family: "Ride";
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 50px;
    position: relative;
    margin: 0px;
    margin-bottom: 63px;

    &::after {
      position: absolute;
      content: "";
      width: 60px;
      bottom: 0px;
      height: 4px;
      background: #ffcc00;
      left: 50%;
      transform: translate(-50%, 19px);
    }
  }

  section.rate-condtn-section {
    .section-head {
      text-align: center;
      font-family: "Ride";
      font-weight: 600;
      font-size: 30px;
      line-height: 44px;
      margin-bottom: 0px;
    }

    .top-head-text {
      text-align: center;

      p.sm-text {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #222222;
      }

      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #222222;
        margin-top: 10px;
        margin-bottom: 16px;
      }
    }
    .rate-condtn-inner {
      display: flex;
      align-items: center;
      column-gap: 35px;
      margin-top: 50px;

      p.rate-msg-txt {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #222222;
      }
    }

    .extra-equip-box {
      width: 100%;
      flex: 1;

      img {
        width: 100% !important;
      }
    }

    .rate-condtn-list {
      ul {
        margin: 0px;
        padding: 0px;

        li {
          margin-left: 22px;
          font-family: "Ride";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #222222;
          padding: 5px 0px;
        }
      }
    }
  }
}

.ml-left {
  margin-left: 80px !important;
}

// media query css start

@media (min-width: 768px) and (max-width: 991px) {
  .team-section {
    .inner-section {
      flex-wrap: wrap;
      row-gap: 20px;

      .person-box {
        width: auto;
        flex-grow: 1;
      }
    }
  }
  .extra-equip-box {
    img {
      width: 100%;
    }
  }

  .ml-left {
    margin-left: 0px !important;
  }
}
.extra-equip-box {
  #background-video {
    background: url(../images/yt-cover.jpg) top center no-repeat;
    background-size: cover;
  }
  #background-video {
    max-width: 100%;
    height: 100%;
    position: relative;
    padding-bottom: 56.25%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.25);
    }

    iframe {
      width: 100%;
      height: 100%;
      display: none;
      position: absolute;
    }
    .play-btn {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      font-size: 0px;
      line-height: 0px;
      background: url(../images/play-btn.png) center center no-repeat;
      background-size: 120px auto;
    }
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .team-section {
    .inner-section {
      padding: 30px 15px;

      .tittle-name {
        font-size: 20px !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .team-section {
    .inner-section {
      column-gap: 15px !important;
      /* flex-direction: column; */
      flex-wrap: wrap;
      row-gap: 20px;

      .person-box {
        width: auto;
        /* flex-wrap: wrap; */
        flex-grow: 1;
      }
    }
  }
  .operation-extra-section {
    .extra-equip-inner {
      flex-direction: column;
      row-gap: 20px;
    }
  }
  section.customer-srvc-section {
    padding: 15px 15px;
    .customer-srvc-inner {
      flex-direction: column-reverse;

      .tittle-name {
        margin-bottom: 40px;
      }
      .extra-equip-box {
        img {
          width: 100%;
        }
      }
    }
  }
  .rate-condtn-wrapper {
    .flex-reverse-cls {
      flex-direction: column-reverse !important;
    }
    .cmn-section {
      margin-bottom: 40px;
    }
    .rate-condtn-inner {
      flex-direction: column;
      // row-gap: 20px;
      margin-top: 20px !important;
    }
    .top-head-text p {
      text-align: left !important;
    }
  }

  .section-tittle-mb {
    font-size: 30px !important;
  }
  .top-head-text-mb {
    font-size: 26px !important;
  }

  .ml-left {
    margin-left: 0px !important;
  }
}
.accordian-box.open {
  .open-sign {
    display: none !important;
  }
  .close-sign {
    display: inline !important;
  }

  .accordian-content {
    display: block !important;
  }
}

