/* html {
  font-size: 10px;
}
 */

.wiz-form-hertz {
  margin-top: 50px;
  margin-bottom: 50px;
}

.wiz-form-hertz .container-with-border {
  background: #ffffff;
  border-bottom: 4px solid #ffcc00;
  -webkit-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 30px;
}

.tab-hertz ul {
  margin-bottom: 0;
}

.tab-hertz ul li {
  width: 33.33% !important;
  text-align: center;
}

.tab-hertz ul li.active button:after {
  background: #ffcc00;
}

.tab-hertz ul li button {
  border: none;
  background: none;
  width: 100%;
  position: relative;
  font-family: "Ride";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 21px;
  color: #5c5f66;
  padding: 10px 15px;
}

.tab-hertz ul li button:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 4px;
  background: #d7d8d8;
  border-radius: 20px;
  bottom: 0px;
  left: 0;
}

.completeProcess {
  background: #ffcc00;
  button {
    font-family: Ride-Bold !important;
    color: #000 !important;
  }
}

.inner-tab-hertz-content h2 {
  font-family: "Ride";
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 32px;
  color: #222222;
}

.tab-wiz-inner-hertz {
  margin-top: 35px;
}

.inner-wiz-form {
  margin-top: 21px;
}

.where-location {
  border: 1px solid #e6e7eb;
  border-radius: 2px;
  font-family: "Ride";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 21px;
  color: #222222;
  padding: 4px 10px;
  background: none;
}

.main-form-hertz {
  margin-top: 23px;
}

.main-form-hertz label {
  display: block;
  font-family: "Ride";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 21px;
  color: #222222;
}

.pickup-location-hertz {
  border: 1px solid #e6e7eb;
  border-radius: 2px;
  margin-top: 21px;
  padding: 11px 0 0px;

  label {
    padding-left: 11px;
    padding-right: 11px;
  }
}

.pickup-location-hertz select {
  border: none;
  font-family: "Ride";
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 27px;
  color: #222222;
  width: 100%;
  background: none;
  -webkit-appearance: none;
}

.pickup-date-hertz {
  border: 1px solid #e6e7eb;
  border-radius: 2px;
  margin-top: 21px;
  padding: 11px;
  height: 70px;
}

.pickup-date-hertz input {
  border: none;
  width: 100%;
  font-family: "Ride";
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 27px;
  color: #222222;
  background: url(../svg/arrow-bottom.svg) 0 no-repeat;
  background-position: right;
}

.pickup-date-hertz input::placeholder {
  font-family: "Ride";
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 27px;
  color: #222222;
  opacity: 1;
}

.pickup-time-hertz {
  border: 1px solid #e6e7eb;
  border-radius: 2px;
  margin-top: 21px;
  padding: 11px 11px 11px 0px;
  height: 70px;
  border-left: 0;
}

.pickup-time-hertz select {
  border: none;
  width: 100%;
  font-family: "Ride";
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 27px;
  -webkit-appearance: none;
  color: #222222;
  padding-left: 11px;
}

.driver-age-hertz {
  border: 1px solid #e6e7eb;
  border-radius: 2px;
  margin-top: 21px;
  padding: 11px 11px 11px 0px;
  height: 70px;
}

.driver-age-hertz select {
  width: 100%;
  border: none;
  font-family: "Ride";
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 27px;
  /* background: none; */
  -webkit-appearance: none;
  color: #222222;
}

.save-btn-group-hertz {
  margin-top: 30px;
}

.btn-continue-hertz {
  background: #ffcc00;
  border: 1px solid #ffcc00;
  border-radius: 2px;
  width: 160px;
  height: 45px;
  font-family: "Ride";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  color: #000000;
}

.label-space-6 {
  margin-left: 2px;
}

.label-space-4 {
  margin-left: 2px;
}

.tab-wiz-inner-hertz .tab-inner-hertz {
  display: none;
}

.promotion-hertz {
  margin-top: 21px;
  background: #f2f4f7;
  border: 1px solid #e6e7eb;
  border-radius: 2px;
  padding: 8px;
  height: 70px;
}

.promotion-hertz select {
  border: none;
  background: none;
  width: 100%;
  font-family: "Ride";
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 27px;
  color: #222222;
  background: none;
  -webkit-appearance: none;
}

.promotion-hertz input {
  border: none;
  background: none;
  width: 100%;
  font-family: "Ride";
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 27px;
  color: #222222;
}

.btn-back-hertz {
  background: rgba(255, 204, 0, 0.5);
  border-radius: 2px;
  font-family: "Ride";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  color: #000000;
  border: 1px solid #ffcc00;
  border-radius: 2px;
  width: 160px;
  height: 45px;
}

.share-and-send-email button {
  border-radius: 2px;
  width: 160px;
  height: 45px;
  font-family: "Ride";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  color: #000000;
  border: 1px solid #000;
  border-radius: 2px;
  margin-top: 30px;
}

.btn-inside-table {
  border: 1px solid #ffcc00;
  border-radius: 3px;
  width: 120px;
  height: 32px;
  text-align: left;
  background: #ffcc00 url(../svg/right-arrow.svg) 0 no-repeat;
  background-position: 94% center;
  padding-right: 10px;
  background-size: 6px;
  font-family: "Ride";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 21px;
  color: #222222;
}

.tab-inner-hertz table h5 {
  font-size: 1.6rem;
}

.tab-inner-hertz table tr {
  vertical-align: middle;
  border-bottom: 1px solid #f4f4f4;
  position: relative;
}

.tab-inner-hertz table tr:hover {
  background: #f2f4f7;
  cursor: pointer;
}

.tab-inner-hertz table tr td {
  padding: 10px 6px;
}

.boxShadow {
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
  &:hover {
    background: transparent !important;
    cursor: default !important;
  }
}

.tab-inner-hertz table tr th {
  font-family: "Ride";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 21px;
  color: #222222;
  padding: 15px;
}

.tab-inner-hertz table thead tr {
  border-bottom: transparent;
}

.modal-pop-up-hertz {
  position: relative;
  display: block;
}

.vehicle-image-hertz {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  img {
    max-width: 100%;
  }
}

.car-detail-hertz h2 {
  font-family: "Ride";
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #222222;
}

.car-detail-hertz ul {
  margin-top: 15px;
}

.car-detail-hertz ul li span {
  font-family: "Ride";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 21px;
  margin-left: 18px;
  color: #222222;
}

.car-detail-hertz ul li {
  width: auto !important;
  text-align: left;
  padding-bottom: 10px;
}

.car-detail-hertz a {
  font-family: "Ride";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 27px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border: 1px solid #222222;
  border-radius: 2px;
  width: 164px;
  height: 45px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  color: #000000;
  margin-top: 13px;
}

.car-detail-hertz a:last-child {
  margin-left: 17px;
}

.descripition-details-hertz p {
  font-family: "Ride";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 21px;
  color: #222222;
}

.descripition-details-hertz ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100% !important;
  text-align: left;
  padding-bottom: 10px;
}

.descripition-details-hertz ul li span {
  font-family: "Ride";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #222222;
}

.descripition-details-hertz ul li button {
  width: 131px;
  height: 42px;
  background: #ffcc00 url(../svg/right-arrow.svg) 0 no-repeat;
  border: 1px solid #ffcc00;
  border-radius: 2px;
  font-family: "Ride";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 24px;
  color: #000000;
  background-position: 90% 57%;
  background-size: 6px;
  padding: 0;
}

.descripition-details-hertz ul li button:after {
  position: absolute;
  content: none;
}

.modal-pop-up-hertz .container {
  padding: 60px 30px 30px 30px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 30px;
}

.btn-close-hertz-modal {
  position: absolute;
  right: 32px;
  top: 20px;
  background: none;
  border: none;
  width: 20px;
  height: 20px;
}

.descripition-details-hertz {
  padding: 0px 30px;
}

#ui-datepicker-div {
  font-size: 1.5rem !important;
}

.send-estimate-btn {
  font-family: "Ride";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 27px;
  color: #000000;
  width: 159px;
  height: 45px;
  background: none;
  border: 1px solid #222222;
  border-radius: 2px;
  margin-top: 22px;
}

@media (max-width: 767px) {
  ul.tab-list-hertz li {
    width: 100% !important;
    white-space: nowrap;
    height: 40px;
  }
  .label-space-6 {
    margin-left: 5px;
  }

  .tab-list-hertz {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .tab-wiz-inner-hertz .col-8 {
    width: 100%;
  }

  /*  .modal-pop-up-hertz {
    overflow: auto;
  } */

  .tab-wiz-inner-hertz .col-5 {
    width: 100%;
  }

  .tab-wiz-inner-hertz .col-6 {
    width: 100%;
  }

  .tab-wiz-inner-hertz .col-4 {
    width: 100%;
  }

  .tab-wiz-inner-hertz .col-3 {
    width: 100%;
  }

  .block-mobile {
    display: block !important;
  }

  .pickup-time-hertz {
    border: 1px solid #e6e7eb;
  }

  .btn-continue-hertz {
    width: 100%;
  }

  .btn-back-hertz {
    width: 100%;
    margin-bottom: 23px;
  }

  .car-detail-hertz a:last-child {
    margin-left: 0;
  }

  .descripition-details-hertz {
    padding: 0;
    margin-top: 23px;
  }

  .descripition-details-hertz ul li {
    display: block;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .car-detail-hertz a {
    width: 100%;
  }

  .descripition-details-hertz ul li button {
    width: 100%;
  }

  .order-sm-first {
    order: 1 !important;
  }
  .order-sm-last {
    order: 2 !important;
    margin-top: 23px;
  }

  .share-and-send-email button {
    width: 100%;
  }

  .share-and-send-email .d-flex {
    display: block !important;
  }

  /*   .modal-pop-up-hertz {
    position: absolute;
    overflow-y: scroll;
  } */

  /*  .modal-inner-hertz {
    max-height: 400px;
  } */

  .btn-close-hertz-modal {
    right: 30px;
  }

  .modelPopup_o_c {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .w-100-tab {
    width: 100%;
  }

  .modal-pop-up-hertz .col-3 {
    width: 100%;
  }
  .modal-pop-up-hertz .col-4 {
    width: 100%;
  }
  .modal-pop-up-hertz .col-5 {
    width: 100%;
  }
  .descripition-details-hertz {
    padding: 0;
    margin-top: 23px;
  }

  .main-form-hertz label {
    font-size: 1.2rem;
  }
}

/* ray code start */

.modelPopup_o_c {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0px;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  display: none;
  &.activePopup {
    display: flex;
  }
}

@media (max-width: 767px) {
  .modelPopup_o_c {
    display: block;
    overflow-x: auto;
    .modal-pop-up-hertz {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

.dropLocation {
  background: #fff url(../svg/arrow-bottom.svg) 0 no-repeat;
  background-position: top 5px right 5px;
  padding-right: 25px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.datepickerIcon input {
  background: #fff url(../svg/date_range.svg) 0 no-repeat;
  background-position: top 5px right 0px;
  padding-right: 25px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.main-form-hertz {
  .row,
  .row > * {
    --bs-gutter-x: 1rem;
  }
}

.pickup-time-hertz label,
.driver-age-hertz label,
.driver-age-hertz select {
  padding-left: 11px;
}

.searchAutocomplete {
  .search-icon {
    display: none;
  }

  .cmyNjn > .wrapper {
    border: none;
    box-shadow: none;
    z-index: 5;
    border-radius: 0px;
  }

  .cmyNjn > .wrapper:focus-within,
  .cmyNjn > .wrapper:active,
  .cmyNjn > .wrapper:hover {
    box-shadow: none;
  }

  .fDbOPw > input {
    padding-left: 0;
    height: 44px;
    font-family: "Ride";
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 27px;
    color: #222222;
    padding-left: 11px;
  }
  .pickup-location-hertz select {
    border: none;
    font-family: "Ride";
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 27px;
    color: #222222;
    width: 100%;
    background: none;
    -webkit-appearance: none;
  }
  .hCpHso > div.line {
    display: none;
  }

  .hCpHso > ul {
    border: 1px solid #e6e7eb;
    width: calc(100% + 2px);
    margin-left: -1px;
    padding-bottom: 0;
  }

  .hCpHso > ul > li {
    width: 100% !important;
    padding: 10px 15px;
    font-family: "Ride";
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    border-bottom: 1px solid #e6e7eb;
    cursor: pointer;

    .ellipsis {
      margin: 0;
    }
  }

  .hCpHso .selected {
    background-color: #ffcc00;
  }

  .locationContainer {
    font-family: Ride-Bold;
    display: block;
  }

  .addressContainer {
    font-size: 1.4rem;
    font-weight: 400;
  }
}

.setMinHeight {
  min-height: 400px;
}

/* code start */
.customerInfo {
  .containerbod {
    border: 1px solid #e6e7eb;
    border-radius: 2px;
    margin-top: 21px;
    padding: 11px 0 0px;
  }

  label {
    padding-left: 11px;
    padding-right: 11px;
    margin: 0;
  }

  .mandatory {
    color: red;
  }
  .input-field {
    border: none;
    width: 100%;
    font-family: "Ride";
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 27px;
    color: #222222;
    padding-left: 11px;
    padding-right: 11px;
    padding-bottom: 10px;
    height: 44px;
  }

  .select-field {
    border: none;
    width: 100%;
    font-family: "Ride";
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 27px;
    -webkit-appearance: none;
    color: #222222;
    padding-left: 11px;
    height: 44px;
  }

  .dropLocation {
    background-position: top 13px right 10px;
  }

  .bg-gray {
    background: #f2f4f7;
    input {
      background: #f2f4f7;
    }
  }

  .textAreaContainer {
    border: none;
    width: 100%;
    font-size: 1.4rem;
    line-height: 27px;
    color: #222222;
    padding-left: 11px;
    padding-right: 11px px;
    padding-bottom: 10px;
    min-height: 100px;
  }

  .infoContainer {
    padding-top: 10px;
    .text-area-nto {
      img {
        cursor: pointer;
      }
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: rgba(var(--text-color), 0.6);
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 5px;
    }
  }

  .btn-continue-hertz {
    background: #ffcc00;
    border: 1px solid #ffcc00;
    border-radius: 2px;
    width: 160px;
    height: 45px;
    font-family: "Ride";
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    color: #000000;
  }

  .btn-box-container {
    padding-top: 25px;
  }
}

/* server error code */
.serverErrorContainer {
  .server-error {
    position: relative;
    font-size: 1.2rem;
    color: #dd143c;
    border: 1px solid #dd143c;
    padding: 8px 10px 8px 50px;
    margin-bottom: 15px;
    display: none;
    &::before {
      content: "";
      background: #dd143c url(../images/error.png) no-repeat center;
      border: none;
      padding: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 40px;
      height: 100%;
    }
  }
}

.autocomplete-loading input {
  background: transparent url(../images/black_spinner.svg) no-repeat center right!important;
  background-size: auto 70%!important;
}

.actions {
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  align-items: center;
}

.btn-inside-table-inverse {
  border: 1px solid #ffcc00;
  background: #222222 url(../svg/right-arrow-white.svg) 0 no-repeat;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 21px;
  color: #ffffff;
  background-position: 94% center;
}
.actions .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 5px 12px;
  border: 1px solid #ccc;
  margin-left: 15px;
}

ul.list-unstyled.extra-list {
  max-width: 250px;
}
ul.list-unstyled.extra-list li {
  display: inline-block;
  padding-right: 15px;
}
ul.list-unstyled.extra-list li span {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}


ul.list-popup-detail-price {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

ul.list-popup-detail-price li
{
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

ul.list-popup-detail-price li span {
  display: inline-block;
  padding-right: 20px;
}

ul.list-popup-detail-price li span:first-child {
  width:50%;
}

ul.list-popup-detail-price li span:last-child {
  padding-right:0px;
  padding: 0;
  margin: 0;
  font-weight: bold;
}
ul.list-popup-detail-price li p {
  padding: 0;
  margin: 0;
}

.divider {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid #ededed;
  width: 100%
}

ul.list-popup-detail-price li.total-amount span {
  font-size: 20px;
  font-weight: bold;
}

.tab-hertz-price ul li{
  width: 100% !important;
}
.mb-zero{
  margin-bottom: 0 !important;
}
.mt-zero{
  margin-top: 0 !important;
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.sticky_div {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
}
.sticky_div img {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.price_black {
  border: 1px solid #222222 !important;
  background: #222222 url(../svg/right-arrow-white.svg) 0 no-repeat;
  color: #ffffff;
  background-position: 94% center;
}

.price_blue {
  border: 1px solid #007BC3 !important;
  background: #007BC3 url(../svg/right-arrow-white.svg) 0 no-repeat;
  color: #ffffff;
  background-position: 94% center;
}

.price_red {
  border: 1px solid red !important;
  background: red url(../svg/right-arrow-white.svg) 0 no-repeat;
  color: #ffffff;
  background-position: 94% center;
}


.electric-vehicle-btn-inside-table {
  border: 1px solid #ffcc00;
  border-radius: 3px;
  width: 120px;
  height: 32px;
  text-align: left;
  background: #ffcc00 0 no-repeat;
  background-position: 94% center;
  padding: 5px;
  background-size: 6px;
  font-family: "Ride";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 21px;
  color: #222222;
  display: block;
}

.vehicle-type-ev {
  /* position: absolute; */
  z-index: 3;
  width: fit-content;
  margin: 0;
  padding: 2px 6px;
  color: #000;
  background-color: #CAF2E6;
  border: 1px solid #088764;
  border-radius: 2px;
}

.inlineList {
  display: flex;
  flex-direction: row;
  /* Below sets up your display method, ex: flex-start|flex-end|space-between|space-around */
  justify-content: flex-start; 
  /* Below removes bullets and cleans white-space */
  list-style: none;
  padding: 0;
  /* Bonus: forces no word-wrap */
  white-space: nowrap;
}

.black_bg {
  border: 1px solid #222222 !important;
  background: #222222 url(../svg/right-arrow-white.svg) 0 no-repeat  !important;
  color: #ffffff  !important;
  background-position: 94% center  !important;
}