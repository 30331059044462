.react-datepicker {
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 2px 18px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 18px -1px rgba(0, 0, 0, 0.2);
  border: 1px solid #e6e7eb;
}

.react-datepicker-popper {
  z-index: 6;
}

.react-datepicker * {
  font-size: 1.4rem;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #e6e7eb;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 7px;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f7f7f7;
  border-bottom: solid 1px #dadada;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
  font-family: Ride-Bold;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-weight: 400;
  border: 0;
  font-family: Ride-Bold;
  padding: 0.7em;
  width: 34px;
  height: 34px;
  font-size: 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #ffd100 !important;
  color: #000;
}

.react-datepicker__day--selected {
  background-color: #eee;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #000;
  transition: -webkit-transform 250ms ease-in-out;
  -webkit-transition: -webkit-transform 250ms ease-in-out;
  -moz-transition: -webkit-transform 250ms ease-in-out;
  -ms-transition: -webkit-transform 250ms ease-in-out;
  -o-transition: -webkit-transform 250ms ease-in-out;
  &:hover {
    background-color: #ffd100 !important;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    font-weight: 700;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #ffd100 !important;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  font-weight: 700;
}
