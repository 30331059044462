.bg-image {
  position: relative;
  background: url(../images/banner.jpg) no-repeat center;
  background-size: cover;
  position: relative;
  &::before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.4);
  }
}
.center-height {
  min-height: 60rem;
}
.outer-form-container {
  max-width: 50rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.form-container {
  background: #ffffff;
  opacity: 0.95;
  border-bottom: 4px solid #ffcc00;
  box-shadow: 0px 0px 0.9rem rgba(0, 0, 0, 0.7);
  width: 100%;
  padding: 4.5rem;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  -o-border-radius: 0.4rem;
  .heading-h1-container {
    font-size: 3.3rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
    margin-bottom: 1.5rem;
  }
  .heading-h5-container,
  .heading-h6-container {
    font-weight: 400;
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }
  a {
    color: var(--bs-link-color);
    text-decoration: none;
  }
}
.form-field {
  position: relative;
  margin-bottom: 15px;
  .formFieldError {
    font-size: 1.2rem;
    color: red;
  }
}
.form-field-inner {
  background: #ffffff;
  border: 1px solid #e6e7eb;
  border-radius: 2px;
  label {
    display: block;
    margin-bottom: 5px;
    font-family: var(--bs-font-family-bold);
    font-size: 1.4rem;
    user-select: none;
    padding: 5px 10px 0;
    margin: 0;
  }
  .input-field {
    min-height: 3rem;
    width: 100%;
    font-size: 1.6rem;
    outline: none;
    border: none;
    padding: 0px 10px;
  }
}
.spacertopform {
  padding-top: 10px;
}

.d-link-contianer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  font-size: 1.4rem;
  margin-bottom: 20px;

  label {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    span {
      display: block;
      padding-left: 5px;
      color: #555555;
    }
  }
}

.para-text {
  font-size: 1.4rem;
  margin-top: 15px;
  a {
    text-decoration: none;
  }
}

@media (max-width: 767px) {
  .center-height {
    min-height: 1px;
  }
  .form-container {
    padding: 3rem 2rem;
  }
  .form-container .heading-h1-container {
    font-size: 3rem;
  }
}
