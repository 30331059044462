.footer-container {
  position: relative;
  background: var(--bs-bg-footer);
  padding-top: 3em;
  padding-bottom: 2rem;
  * {
    font-size: 1.2rem;
  }

  .heading-container {
    font-weight: 700;
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;

    li {
      display: block;

      a {
        color: var(--bs-body-color);
        text-decoration: none;
        margin-bottom: 5px;
        padding: 2px 0;
        display: inline-block;
      }
    }
  }

  .footer-logo {
    display: inline-flex;
    flex-wrap: wrap;
  }

  .copyright-container {
    margin: 0;
    padding: 0;
    padding-top: 15px;
  }
}

