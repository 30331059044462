.searchAutocomplete {
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow: auto;
    max-height: 400px;
  }
  
  .sugg-option {
    //display: flex;
  }
  .icon-wrap img {
    width: 50px;
    margin-right: 10px;
  }
  
    .search-icon {
      display: none;
    }
  
    .react-autosuggest__suggestions-container {
      border: none;
      box-shadow: none;
      z-index: 5;
      border-radius: 0px;
    }
  
    .react-autosuggest__suggestions-container:focus-within,
    .react-autosuggest__suggestions-container:active,
    .react-autosuggest__suggestions-container:hover {
      box-shadow: none;
    }
  
     input {
      padding-left: 0;
      height: 44px;
      font-family: "Ride";
      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 27px;
      color: #222222;
      padding-left: 11px;
      width:100%;
      border: none;
    }
    .pickup-location-hertz select {
      border: none;
      font-family: "Ride";
      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 27px;
      color: #222222;
      width: 100%;
      background: none;
      -webkit-appearance: none;
    }
    .react-autosuggest__suggestions-container > div.line {
      display: none;
    }
  
    .react-autosuggest__suggestions-container > ul {
      border: 1px solid #e6e7eb;
      width: calc(100% + 2px);
      margin-left: -1px;
      padding-bottom: 0;
    }
  
    .react-autosuggest__suggestions-container > ul > li {
      width: 100% !important;
      padding: 10px 15px;
      font-family: "Ride";
      font-style: normal;
      font-weight: 600;
      font-size: 1.4rem;
      border-bottom: 1px solid #e6e7eb;
      cursor: pointer;
  
      .sugg-option {
        margin: 0;
        text-align: left;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  
    .react-autosuggest__suggestion--highlighted {
      background-color: #ffcc00 !important;
    }
  
    .locationContainer {
      font-family: Ride-Bold;
      display: block;
      width:100%;
      text-align: left;
    }
  
    .addressContainer {
      font-size: 1.4rem;
      font-weight: 400;
      width:100%;
      text-align: left;
    }
  }