.search-box {
  position: relative;
  font-family: var(--font-ride);
  margin: 0px 0 0 30px;
  .search-icon {
    position: absolute;
    left: 8px;
    top: 10px;
    color: rgba(var(--black), 0.3);
    background: url(../svg/search.svg) no-repeat center;
    width: 20px;
    height: 20px;
  }

  .form-search {
    height: 38px;
    padding: 10px 10px 10px 30px;
    border-radius: 2px;
    border: 1px solid rgba(var(--black), 0.1);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    &:focus {
      outline: none;
    }
  }
}

.calendarWrap{
  position: absolute;
  z-index: 999;
}

.close-calender {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20px;
  height: 20px;
  background: #f2f4f7;
  position: absolute;
  right: 0;
  top: 18px;
  text-indent: -99999px;
  cursor: pointer;
}

.date-range-icon{
  position: absolute;
  left: 8px;
  top: 10px;
  color: rgba(var(--black), 0.3);
  background: url(../svg/date_range.svg) no-repeat center;
  width: 20px;
  height: 20px;
  z-index:1000;
}

.rdrInputRanges{
  display:none;
}

