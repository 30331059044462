//@import "bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap/scss/bootstrap";

/* Webfont: Ride */

@font-face {
  font-family: "Ride";
  src: url("./assets/fonts/Ride/Ride.eot");
  /* IE9 Compat Modes */
  src: url("./assets/fonts/Ride/Ride.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Ride/Ride.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Ride/Ride.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/Ride/Ride.svg#Ride")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Ride-Italic */

@font-face {
  font-family: "Ride-Italic";
  src: url("./assets/fonts/Ride/Ride-Italic.eot");
  /* IE9 Compat Modes */
  src: url("./assets/fonts/Ride/Ride-Italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Ride/Ride-Italic.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Ride/Ride-Italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Ride/Ride-Italic.svg#Ride-Italic") format("svg");
  /* Legacy iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Ride-Bold */

@font-face {
  font-family: "Ride-Bold";
  src: url("./assets/fonts/Ride-Bold/Ride-Bold.eot");
  /* IE9 Compat Modes */
  src: url("./assets/fonts/Ride-Bold/Ride-Bold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Ride-Bold/Ride-Bold.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Ride-Bold/Ride-Bold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Ride-Bold/Ride-Bold.svg#Ride-Bold") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: Ride-BoldItalic */

@font-face {
  font-family: "Ride-BoldItalic";
  src: url("./assets/fonts/Ride-Bold/Ride-BoldItalic.eot");
  /* IE9 Compat Modes */
  src: url("./assets/fonts/Ride-Bold/Ride-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Ride-Bold/Ride-BoldItalic.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Ride-Bold/Ride-BoldItalic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Ride-Bold/Ride-BoldItalic.svg#Ride-BoldItalic")
      format("svg");
  /* Legacy iOS */
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: RideCondensed */

@font-face {
  font-family: "RideCondensed";
  src: url("./assets/fonts/Ride-Condensed/RideCondensed.eot");
  /* IE9 Compat Modes */
  src: url("./assets/fonts/Ride-Condensed/RideCondensed.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Ride-Condensed/RideCondensed.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Ride-Condensed/RideCondensed.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Ride-Condensed/RideCondensed.svg#RideCondensed")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: RideCondensed-Italic */

@font-face {
  font-family: "RideCondensed";
  src: url("./assets/fonts/Ride-Condensed/RideCondensed-Italic.eot");
  /* IE9 Compat Modes */
  src: url("./assets/fonts/Ride-Condensed/RideCondensed-Italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Ride-Condensed/RideCondensed-Italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/Ride-Condensed/RideCondensed-Italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Ride-Condensed/RideCondensed-Italic.svg#RideCondensed-Italic")
      format("svg");
  /* Legacy iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: RideExtrabold */

@font-face {
  font-family: "RideExtrabold";
  src: url("./assets/fonts/Ride-Extrabold/RideExtrabold.eot");
  /* IE9 Compat Modes */
  src: url("./assets/fonts/Ride-Extrabold/RideExtrabold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Ride-Extrabold/RideExtrabold.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Ride-Extrabold/RideExtrabold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Ride-Extrabold/RideExtrabold.svg#RideExtrabold")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: RideExtrabold-Italic */

@font-face {
  font-family: "RideExtrabold-Italic";
  src: url("./assets/fonts/Ride-Extrabold/RideExtrabold-Italic.eot");
  /* IE9 Compat Modes */
  src: url("./assets/fonts/Ride-Extrabold/RideExtrabold-Italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Ride-Extrabold/RideExtrabold-Italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/Ride-Extrabold/RideExtrabold-Italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Ride-Extrabold/RideExtrabold-Italic.svg#RideExtrabold-Italic")
      format("svg");
  /* Legacy iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: RideLight */

@font-face {
  font-family: "RideLight";
  src: url("./assets/fonts/Ride-Light/RideLight.eot");
  /* IE9 Compat Modes */
  src: url("./assets/fonts/Ride-Light/RideLight.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Ride-Light/RideLight.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Ride-Light/RideLight.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Ride-Light/RideLight.svg#RideLight") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: RideLight-Italic */

@font-face {
  font-family: "RideLight-Italic";
  src: url("./assets/fonts/Ride-Light/RideLight-Italic.eot");
  /* IE9 Compat Modes */
  src: url("./assets/fonts/Ride-Light/RideLight-Italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Ride-Light/RideLight-Italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Ride-Light/RideLight-Italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Ride-Light/RideLight-Italic.svg#RideLight-Italic")
      format("svg");
  /* Legacy iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Ride-SemiBold */

@font-face {
  font-family: "Ride-SemiBold";
  src: url("./assets/fonts/Ride-SemiBold/Ride-SemiBold.eot");
  /* IE9 Compat Modes */
  src: url("./assets/fonts/Ride-SemiBold/Ride-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Ride-SemiBold/Ride-SemiBold.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Ride-SemiBold/Ride-SemiBold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Ride-SemiBold/Ride-SemiBold.svg#Ride-SemiBold")
      format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: Ride-SemiBoldItalic */

@font-face {
  font-family: "Ride-SemiBoldItalic";
  src: url("./assets/fonts/Ride-SemiBold/Ride-SemiBoldItalic.eot");
  /* IE9 Compat Modes */
  src: url("./assets/fonts/Ride-SemiBold/Ride-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Ride-SemiBold/Ride-SemiBoldItalic.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/Ride-SemiBold/Ride-SemiBoldItalic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Ride-SemiBold/Ride-SemiBoldItalic.svg#Ride-SemiBoldItalic")
      format("svg");
  /* Legacy iOS */
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/*Scrollbar CSS*/

::-webkit-scrollbar {
  width: 8px;
  height: 7px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb:vertical {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
  border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px;
  /*Prevent it from getting too small */
}

::-webkit-scrollbar-thumb:vertical:active {
  /*background: rgba(0,0,0,0.61); /* Some darker color when you click it*/
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
  border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px;
  /*Prevent it from getting too small */
}

::-webkit-scrollbar-thumb:horizontal:active {
  /*background: rgba(0,0,0,0.61); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-font-family-bold: "Ride-Bold";
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #222222;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.4rem;
  --bs-link-color: #355ad8;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-button-bg: #ffcc00;
  --bs-bg-footer: #f5f5f5;
  --bs-primary-color: #ffcc00;
  --primary-color: 255, 204, 0;
  --text-color: 34, 34, 34;
  --light-gray: 242, 244, 247;
  --black: 0, 0, 0;
  --modal-btn: 53, 90, 216;
  --modal-title-bg: 230, 231, 235;
  --white: 255, 255, 255;
  --alert: 255, 0, 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

html {
  font-size: 0.625rem;
}

input,
textarea,
select {
  &:focus,
  &:focus-visible {
    border-color: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: 0;
    outline: none;
  }
}

body {
  margin: 0;
  font-family: "Ride", var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: normal;
  color: var(--bs-body-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * 0.5 * 2);
  padding-left: calc(var(--bs-gutter-x) * 0.5 * 2);
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(2 * -1 * var(--bs-gutter-y));
  margin-right: calc(2 * -0.5 * var(--bs-gutter-x));
  margin-left: calc(2 * -0.5 * var(--bs-gutter-x));
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * 0.5 * 2);
  padding-left: calc(var(--bs-gutter-x) * 0.5 * 2);
}

.font-set {
  font-size: 1.3rem;
}

.container {
  max-width: 1640px;
}

.btn-yellow {
  min-width: 130px;
  height: 45px;
  text-decoration: none;
  padding: 10px 20px;
  background: var(--bs-button-bg);
  border: 1px solid var(--bs-button-bg);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  font-size: 1.4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bs-font-family-bold);
}

.btn-blue {
  min-width: 130px;
  height: 45px;
  text-decoration: none;
  padding: 10px 20px;
  background: var(--bs-link-color);
  border: 1px solid var(--bs-link-color);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  font-size: 1.4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bs-font-family-bold);
  color: #fff;
  &:hover {
    color: #fff;
  }
}

.white-box {
  background: #ffffff;
  border-bottom: 4px solid var(--bs-primary-color);
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 25px;
  .title {
    font-size: 2.4rem;
    font-weight: 600;
  }
}

.page-title {
  background: #f2f4f7;
  padding: 2rem 0px;
  .title {
    font-size: 2.2rem;
    color: var(--bs-body-color);
    font-weight: 600;
    font-family: var(--bs-font-family-bold);
  }
}

.form-wrapper {
  margin-top: 50px;
  margin-bottom: 50px;
  .row {
    margin-bottom: 20px;
  }
  .custom-field {
    border: 1px solid #c4c6cc;
    padding: 5px 10px;
    box-sizing: border-box;
    width: 100%;
    min-width: 125px;
    margin: 5px 0;
    position: relative;
    > label {
      font-weight: bold !important;
      margin: 0;
      box-sizing: border-box;
      padding: 4px 0;
      font-size: 14px;
    }
    input,
    select {
      border: 0;
      box-shadow: none;
      padding: 0;
      width: 100%;
      height: 30px;
      margin-bottom: 0;
      color: #222;
      font-size: 18px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    &.disabled {
      background: #e6e7eb;
    }
  }
  .checkboxes {
    .checkbox {
      display: flex;
      align-items: center;
      width: 25%;
      label {
        font-size: 1.4rem;
        display: flex;
        align-items: center;
      }
      input {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
    &.horizontal {
      display: flex;
    }
  }
  .head {
    border-bottom: 3px solid var(--bs-yellow);
    font-size: 2rem;
    padding: 0px 0px 15px 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .error {
    color: red;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .form-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    .row {
      margin-bottom: 0px;
    }
    .checkboxes {
      flex-direction: column;
      .checkbox {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
}

.red {
  color: red;
}
.set-default-fs {
  font-size: 1.4rem;
}

.toaster-popup {
  border: "1px solid #ffcc00";
  padding: "16px";
  color: "#000";
  background-color: "#fff";
  font-size: 1.4rem;
}

.errorLabel {
  color: red;
  font-size: 1.2rem;
  display: block;
  padding: 3px 10px 0 10px;
}

.hide{
  display: none !important;
}

.react-autosuggest__container--open {
  position: relative;
}
.react-autosuggest__suggestions-container{
  position: absolute;
  width: 100%;
  background-color: #fff;
}

.form_bg {
  margin: 0px;
  background: url('assets/images/bg_booking.jpg');
  border-radius: 5px;
  padding-bottom: 30px;
  padding-top: 15px;
  background-position: center;
  background-size: cover;
  padding-right:10px;
  padding-left:10px;
}

.searchAutocomplete .react-autosuggest__suggestions-container>ul>li .sugg-option {
  white-space: initial !important;
}

.calendarWrap {
  z-index: 9 !important;
}

::-webkit-scrollbar {
width: 18px !important;
}


@media screen and (max-height: 700px) {
  .modal-inner-hertz {
    max-height: 600px;
    overflow:auto;
  }
}