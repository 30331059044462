.outer-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.table-data {
  min-height: 400px;


  
  font-size: 1.4rem;
  .table > :not(caption) > * > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 3.5rem;
    padding-right: 2rem;
  }
  .table-light th {
    background-color: #f3f4f8;
    font-family: var(--bs-font-family-bold);
    position: relative;
    cursor: pointer;
    user-select: none;

    &::before {
      content: "";
      background: url(../svg/asc-desc.svg) no-repeat center;
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 5px;
      top: 17px;
    }

    &.asc {
      &::before {
        content: "";
        background: url(../svg/asc.svg) no-repeat center !important;
      }
    }

    &.desc {
      &::before {
        content: "";
        background: url(../svg/desc.svg) no-repeat center !important;
      }
    }
  }

  .edit-container {
    span {
      display: inline-block;
      cursor: pointer;
      user-select: none;
      width: 20px;
      height: 20px;

      img {
        max-width: 100%;
      }
    }
  }
}

.c-inner-container {
  padding-bottom: 25px;
}

.left-container {
  font-size: 1.3rem;

  select {
    margin: 0px 5px;
    background: #f2f2f2;
    border-radius: 2px;
    outline: none;
    border: none;
    padding: 8px 10px;
  }
}

.right-container {
  .btn {
    background: #ffcc00;
    border-radius: 2px;
    font-family: var(--bs-font-family-bold);
    padding: 10px 14px;
    font-size: 13px;
    min-width: 100px;
    position: relative;
    padding-left: 35px;
    text-align: left;
    outline: none;

    &:before {
      position: absolute;
      top: 12px;
      width: 18px;
      height: 18px;
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
      left: 10px;
    }

    &:last-child {
      margin-left: 16px;
    }

    &.btn-export {
      &:before {
        background: url(../svg/export.svg) center !important;
      }
    }

    &.btn-add-new {
      &:before {
        background: url(../svg/plus.svg) center !important;
      }
    }
  }
}

.pagination-container {
  padding: 15px 0 10px 0;
  user-select: none;
  .hideElement {
    display: none;
  }

  /* .page-item.noBorder a {
    border: none;
  } */

  .page-link:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 204, 0, 0.25);
  }

  ul {
    li {
      padding-left: 5px;
      padding-right: 5px;
      a {
        font-size: 1.4rem;
        color: #222;
        padding: 6px 11px;
        border-color: #e0e0e0;

        &:hover {
          background: #ffcc00;
          border-color: rgb(255, 204, 0);
          border-radius: 2px;
          color: #222;
        }
      }
    }
  }

  .page-item.active {
    a {
      background: #ffcc00;
      border-color: #ffcc00;
      border-radius: 2px;
      color: #222;
    }
  }
}

@media (max-width: 767px) {
  .c-inner-container {
    padding-bottom: 15px;
  }
  .right-container {
    margin-bottom: 25px;
  }
}
