.header-container {
  width: 100%;
  border-bottom: 4px solid #fc0;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 999;
  padding-top: 0rem;
  padding-bottom: 0rem;
  header {
    display: flex;
    height: 60px;
    align-items: center;
  }
  .logo {
    display: inline-flex;
    align-items: center;
    img {
      height: 35px;
      width: auto;
    }
  }
  .main-menu {
    flex: 1;
    display: flex;
    height: 100%;
    ul,
    li {
      list-style: none;
      list-style: none;
      margin: 0px;
      padding: 0px;
    }
    .primary-menus {
      > ul {
        > li {
          font-size: 14px;
          height: 100%;
          a {
            text-decoration: none;
            color: #222;
            font-size: 14px;
          }
          &:hover {
            > a {
              color: #2f95f7;
            }
            .child {
              display: block;
              li {
                &:hover {
                  .subchild {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
    .message {
      max-width: 300px;
      text-align: center;
      font-weight: bold;
      margin-right: 100px;
      margin-left: auto;
      display: inline-flex;
      align-items: center;
    }
    .profile-menu {
      span {
        background: var(--bs-yellow);
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 1.6rem;
        border-radius: 100%;
        cursor: pointer;
      }
      .child {
        a {
          text-decoration: none;
          color: #222;
          font-size: 14px;
        }
      }
      &:hover {
        .child {
          display: block;
        }
      }
    }
  }
  .hamburger-btn {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .header-container {
    header {
      .primary-menus {
        display: flex;
        flex: 1;
        > ul {
          display: flex;
          align-items: center;
          background: #fff;
          > li {
            padding: 0px 9px;
            align-items: center;
            display: inline-flex;
          }
        }
        .child {
          position: absolute;
          display: none;
          z-index: 1000;
          top: 54px;
          &:before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent #fc0 transparent;
            position: absolute;
            top: 2px;
            right: auto;
            left: auto;
            transform: translate(-50%, -50%);
            text-align: center;
            margin-left: 30px;
          }
          ul {
            display: flex;
            flex-direction: column;
            margin: 0px;
            padding: 0px;
            width: auto;
            margin: 0.5em 0 0 0;
            padding: 0;
            border: 0px;
            border-top: 0 none;
            box-shadow: 0 1px 6px #4c4c4c;
            z-index: 1;
            position: relative;
            li {
              background-color: #fff;
              margin: 0;
              padding: 0px 9px;
              width: 300px;
              height: 50px;
              display: flex;
              align-items: center;
              position: relative;
              a {
                font-size: 13px;
                display: flex;
                height: 100%;
                width: 100%;
                align-items: center;
                &.addSubchild {
                  flex: 1 0 0%;
                  justify-content: space-between;

                  span {
                    display: flex;
                    flex-wrap: wrap;
                    width: 30px;
                    height: 30px;
                    background: transparent url(../svg/dd-arrow.svg) no-repeat
                      center;
                    background-size: 40%;
                    transform: rotate(-90deg);
                    -webkit-transform: rotate(-90deg);
                    -moz-transform: rotate(-90deg);
                    -ms-transform: rotate(-90deg);
                    -o-transform: rotate(-90deg);
                  }
                }

              }
              &:hover {
                background: #e2e2e2;
              }
            }
          }
          .subchild {
            position: absolute;
            display: none;
            z-index: 1000;
            top: -7px;
            left: 100%;
          }
        }
      }
      .profile-menu {
        position:relative;
        display: inline-flex;
        align-items: center;
        .head {
          display: none;
        }
        .child {
          position: absolute;
          display: none;
          z-index: 1000;
          top: 54px;
          right: -20px;
          &:before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent #fc0 transparent;
            position: absolute;
            top: 2px;
            right: auto;
            left: auto;
            transform: translate(-50%, -50%);
            text-align: center;
            right: 20px;
          }
          ul {
            display: flex;
            flex-direction: column;
            margin: 0px;
            padding: 0px;
            width: auto;
            margin: 0.5em 0 0 0;
            padding: 0;
            border: 0px;
            border-top: 0 none;
            box-shadow: 0 1px 6px #4c4c4c;
            z-index: 1;
            position: relative;
            li {
              background-color: #fff;
              margin: 0;
              padding: 0px 9px;
              width: 200px;
              height: 50px;
              display: flex;
              align-items: center;
              position: relative;
              a {
                font-size: 13px;
                display: flex;
                height: 100%;
                width: 100%;
                align-items: center;
                color: #222;
                text-decoration: none;
              }
              &:hover {
                background: #e2e2e2;
              }
            }
          }
          .subchild {
            position: absolute;
            display: none;
            z-index: 1000;
            top: -7px;
            left: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .header-container {
    header {
      .main-menu {
        .message {
          display: none;
        }
        .primary-menus {
          display: none;
          position: absolute;
          top: 62px;
          left: 0px;
          width: 100%;
          height: 100vh;
          background: #fff;
          overflow: auto;
          padding-bottom: 62px;
          .hasChild {
            position: relative;
            span {
              width: 40px;
              height: 40px;
              position: absolute;
              right: 0;
              top: 0px;
              margin-top: -3px;
              &:after {
                content: "";
                border: solid black;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 4px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                -webkit-transform: translate(-50%, -50%) rotate(45deg);
                position: absolute;
              }
            }
            &.active {
              span {
                &:after {
                  transform: translate(-50%, -50%) rotate(-135deg);
                  -webkit-transform: translate(-50%, -50%) rotate(-135deg);
                }
              }
            }
          }
          ul {
            flex-direction: column;
            width: 100%;
            li {
              width: 100%;
              flex-direction: column;
              align-items: flex-start;
              a {
                display: block;
                padding: 1rem 1rem;
              }
              .child {
                margin-left: 10px;
                display: none;
                li {
                  width: 100%;
                  flex-direction: column;
                  align-items: flex-start;
                  a {
                    padding-left: 0px;
                  }
                }
                &.open {
                  display: block;
                }
              }
            }
          }
          &.open {
            display: block;
          }
        }
        .profile-menu {
          > span {
            display: none;
          }
          .head {
            font-weight: bold;
            padding: 2rem 1rem;
            margin-bottom: 0px;
          }
          .child {
            display: block;
            position: relative;
          }
        }
        .hamburger-btn {
          display: block;
          width: 44px;
          height: 44px;
          background: url("../images/hamburger-menu.png") no-repeat center;
          margin-left: auto;
          margin-top: 10px;
        }
      }
    }
  }
}
