/* @import "banner.scss"; */

.banner-hertz-res {
  width: 100%;
  min-height: 320px;
  background: url("../images/confirm-reservration-background.jpeg") 0 no-repeat;
  background-size: cover;
  padding: 45px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  .container-max-res {
    display: flex;
    flex-basis: 750px;
    display: flex;
    margin: 0px auto;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 30px;
    text-align: center;
  }
  h2 {
    font-family: "Ride";
    font-style: normal;
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 32px;
    text-align: center;
    padding-top: 43px;
    color: #222222;
  }
  p {
    font-family: "Ride";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    padding-top: 6px;
    color: #222222;
  }
}

@media (max-width: 768px) {
  .container-max-res {
    margin: 0px 20px !important;
  }
}

/* @import "reservration.scss"; */

.res-details-conf {
  text-align: center;
  margin-top: 50px;
}

/* @import "section.scss"; */

.common-reservration-section {
  margin-top: 70px;
  .max-container-750 {
    border: 1px solid rgba(151, 151, 151, 0.4);
    padding: 19px 30px 37px 30px;

    h2 {
      font-family: "Ride";
      font-style: normal;
      font-weight: 600;
      font-size: 2.2rem;
      line-height: 32px;
      color: #222222;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 60px;
        height: 4px;
        background: #ffcc00;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .col-reservration {
      margin-top: 30px;
    }

    .reservration-details-re {
      margin-top: 20px;
      p {
        font-family: "Ride";
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 21px;
        color: #222222;
        margin: 0;
        text-transform: capitalize;
      }
      h5 {
        font-size: 1.6rem;
        font-weight: 500;
        padding-top: 3px;
        text-transform: uppercase;
      }
    }
  }
}

.your-iti-re {
  margin-top: 30px !important;
  .inner-iti-re {
    margin-top: 30px;
    h3 {
      font-family: "Ride";
      font-style: normal;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 30px;
      color: #222222;
      margin: 0;
    }

    p {
      font-family: "Ride";
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 21px;
      margin: 0;
      padding-top: 3px;
      color: #222222;
    }

    h4 {
      font-family: "Ride";
      font-style: normal;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 50px;
      margin: 0;
      color: #222222;
    }

    h5 {
      font-family: "Ride";
      font-style: normal;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 21px;
      color: #222222;
      span {
        display: block;
        font-weight: 300;
      }
    }
  }
}

.pr-60 {
  padding-right: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.border-right {
  position: relative;
}

.border-right:after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background: rgba(151, 151, 151, 0.4);
  right: 0;
  top: 15px;
}

@media (max-width: 768px) {
  .max-container-750 {
    margin: 30px 20px !important;
  }

  .pl-60 {
    padding-left: 0;
  }
  .border-right::after {
    position: absolute;
    content: none !important;
  }

  /* .inner-iti-re {
    h3 {
      position: relative;
      display: inline-block;
    }
    h3:after {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      background: url("../svg/right-arrow.svg") 0 no-repeat;
      transform: rotate(90deg) !important;
      top: 15px;
      background-size: contain;
      left: 110%;
      -webkit-transform: rotate(90deg) !important;
      -moz-transform: rotate(90deg) !important;
      -ms-transform: rotate(90deg) !important;
      -o-transform: rotate(90deg) !important;
    }
    p {
      display: none;
    }
    h4 {
      display: none;
    }
    h5 {
      display: none;
    }
  } */

  .pr-60 {
    padding-right: 0;
  }
}

/* @import "extras.scss"; */
.extras-re .max-container-750 {
  border: 1px solid rgba(151, 151, 151, 0.4);
  padding: 19px 30px 37px 30px;
  margin-top: 30px;
  h2 {
    font-family: "Ride";
    font-style: normal;
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 32px;
    color: #222222;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      width: 60px;
      height: 4px;
      background: #ffcc00;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .inner-extras-re {
    margin-top: 35px;
    p {
      font-family: "Ride";
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 21px;
      color: #222222;
    }
  }
}

@media (max-width: 768px) {
  .inner-extras-re .d-flex {
    flex-wrap: wrap;
  }
}

/* @import "estimated-amount.scss"; */

.estimated-amount-re {
  margin-top: 49px;
  .max-container-750 {
    border-bottom: 1px solid rgba(151, 151, 151, 0.4);
    padding-bottom: 15px;
  }
  p {
    font-family: "Ride";
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 25px;
    color: #000000;
  }

  h4 {
    font-family: "Ride";
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 30px;
    color: #000000;
  }
}

@media (max-width: 768px) {
  .estimated-amount-re .d-flex {
    display: block !important;
  }
}
/* @import "your-vehice.scss"; */

.your-vehicle-re {
  margin-top: 20px;
  h2 {
    font-family: "Ride";
    font-style: normal;
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 32px;
    color: #222222;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      width: 60px;
      height: 4px;
      background: #ffcc00;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }

  .vehicle-re-inner {
    margin-top: 30px;
    h3 {
      font-family: "Ride";
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      line-height: 30px;
      margin: 0;
      padding-bottom: 5px;
      color: #222222;
    }
    h4 {
      font-family: "Ride";
      font-style: normal;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 21px;
      color: #222222;
    }

    p {
      font-family: "Ride";
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 25px;
      margin: 0;
      padding-bottom: 2px;
      color: #222222;
    }
  }

  .assistance-text {
    margin-top: 50px;
    p {
      font-family: "Ride";
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 21px;
      color: #000000;

      opacity: 0.6;
    }
  }
}

@media (max-width: 768px) {
  .vehicle-re-inner .justify-content-center {
    justify-content: start !important;
  }
}

/* @import "notes-section.scss"; */

.notes-re {
  margin-top: 50px;
  h2 {
    font-family: "Ride";
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 27px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.4);
    color: #222222;
    padding-bottom: 5px;
  }
  p {
    font-family: "Ride";
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 21px;
    padding-bottom: 10px;
    color: #222222;
  }
}
/* @import "rules-section.scss"; */
.rules-re {
  margin-top: 30px;
  padding-bottom: 50px;
  details {
    margin-top: 20px;

    summary {
      font-family: "Ride";
      font-style: normal;
      font-weight: 600;
      font-size: 2.2rem;
      line-height: 32px;
      color: #222222;
    }
  }
  p {
    font-family: "Ride";
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 30px;
    padding: 7px 27px;
    color: #222222;
  }
  ul {
    padding-left: 50px;
    li {
      font-family: "Ride";
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 20px;
      padding-bottom: 10px;
      color: #222222;
    }
  }
}

.max-container-750 {
  max-width: 750px;
  display: block;
  margin: 0px auto;
}

.border-bottom-yellow-heading {
  font-family: "Ride";
  font-style: normal;
  font-weight: 600;
  font-size: 3.4rem;
  line-height: 50px;
  color: #222222;
  position: relative;
}

.border-bottom-yellow-heading:after {
  position: absolute;
  content: "";
  width: 60px;
  height: 4px;
  background: #ffcc00;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
}
