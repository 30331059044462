.loader-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    left: 0px;
    top: 0px;
    z-index: 11111;
    position: fixed;
    background-color: rgba(0, 0, 0, .6);
    .loading-fg {
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        border: 2px solid #ededed;
        width: 76px;
        height: 64px;
        padding: 10px;
        z-index: 100001;
        background: url("../images/loader.gif") no-repeat center;
        background-color: #FFF;
        opacity: 0.8
    }
}