.tags-upper-banner {
  width: 100%;
  min-height: 91px;
  background: url("../images/tabs-dark-bg.jpeg") 0 no-repeat;
  padding: 10px 0px;
  background-size: cover;
  p {
    font-family: "Ride";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 18px;
    margin: 0;
    color: #ffffff;
  }
  h2 {
    font-family: "Ride";
    font-style: normal;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 32px;
    margin: 0;
    color: #ffffff;
  }
  h3 {
    font-family: "Ride";
    font-style: normal;
    font-weight: 400;
    font-size: 3.2rem;
    line-height: 47px;
    color: #ffffff;
    margin: 0;
  }
  .border-1-right {
    position: relative;
  }
  .border-1-right {
    &::after {
      position: absolute;
      content: "";
      width: 1px;
      height: 91px;
      background: #fff;
      right: 0;
      top: -8px;
    }
  }
  .pad-60 {
    padding-left: 60px;
  }
}

@media (max-width: 767px) {
  .tags-upper-banner {
    overflow-x: auto;
    overflow-y: hidden;
    .col-4 {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tags-upper-banner {
    overflow-x: auto;
    overflow-y: hidden;
    .col-4 {
      width: 60%;
    }
  }
}
