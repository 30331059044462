.modal-box-countainer {
  width: 100%;
  height: 100%;
  background-color: rgba(var(--black), 0.2);
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  z-index: 999;
  overflow-y: hidden;
}

.modal-box {
  background-color: rgba(var(--white), 1);
  width: 820px;
  height: 700px;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  -moz-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  -o-transform: translate(-50%, 0%);
  box-shadow: 0px 0px 9px rgba(var(--black), 0.2);
  z-index: 9999;

  &.small-modal {
    max-width: 500px;
    transform: translate(0%, 0%);
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    -o-transform: translate(0%, 0%);
    top: auto;
    left: auto;
    min-height: 300px;
    display: flex;
    padding: 25px;
    align-items: center;
    justify-content: center;
    font-family: var(--font-ride);
    position: relative;
    h2 {
      padding: 0 55px;
      text-align: center;
    }
  }

  .close-btn {
    position: absolute;
    right: 25px;
    top: 20px;
    padding: 0;
    outline: none;
    border: none;

    &:focus,
    &:active {
      outline: none;
    }
  }
}

.height-setting {
  overflow-y: scroll !important;
  max-height: 540px;
  position: absolute;
}

.modal-title {
  font-size: 24px;
  line-height: 35px;
  font-weight: 700;
  margin-bottom: 35px;
}

.etra-fee-title {
  background: rgba(var(--modal-title-bg), 1);
  text-align: left;
  padding: 16px 25px;
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
}

// inner box setting

.modal-body-box {
  .d-flex-model {
    justify-content: center;
    gap: 40px;
    display: flex;
    flex-wrap: wrap;
  }
}

.confirmation-btn,
.add-btn,
.remove-btn {
  border: 1px solid rgba(var(--black), 1);
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  padding: 8px 40px;
  border-radius: 2px;
  background: #fff;
  cursor: pointer;

  &:hover,
  &:focus {
    border: 1px solid rgba(var(--black), 1);
    background: #fff;
  }
}

.add-btn,
.remove-btn {
  width: 100%;

  &:hover {
    background: rgba(var(--modal-btn), 1);
    border-color: rgba(var(--modal-btn), 1);
    color: rgba(var(--white), 1);
  }
}

//extra-fee-inner

.extra-fee-inner {
  padding: 13px 25px;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 32px;
  }

  .ef-d-flex {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
    margin-bottom: 30px;
    flex-wrap: wrap;

    .fee-details {
      background: rgba(var(--white), 1);
      border: 1px solid #c4c6cc;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      padding: 13px 25px 13px 13px;
      width: 33%;
      min-height: 330px;
      position: relative;
      flex: 1 1 30%;
      max-width: 33%;
      
      h4 {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #222222;
        min-height: 170px;

        a {
          display: block;
        }
      }

      .coverage-para {
        min-height: auto;
      }

      .p-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 13px;
      }

      h5 {
        font-weight: 700;
        font-size: 22px;
        line-height: 32px;

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          display: block;
        }
      }

      .inclues {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: rgba(var(--modal-btn), 1);
        width: 100%;
        text-align: center;
        border: none;
        background: transparent;
      }

      .available-btn {
        font-weight: 600;
        text-align: center;
        width: 100%;
        font-size: 18px;
        line-height: 27px;
        border: none;
        background: transparent;
      }
    }
  }
}

.modal-footer-box {
  position: absolute;
  bottom: 0;
  padding: 25px;
  left: 0;
  background-color: rgba(var(--white), 1);
  width: 100%;

  .continue {
    background-color: rgba(var(--primary-color), 1);
    border: none;
    border-radius: 2px;
    border-radius: 2px;
    padding: 9px 40px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }
}
@media (max-width: 991px) {
  .modal-box {
    width: 90%;
  }
  .extra-fee-inner .ef-d-flex {
    flex-wrap: wrap;
    .fee-details {
      width: 47%;
    }
  }
}
@media (max-width: 767px) {
  .modal-box {
    .close-btn {
      right: 10px;
      top: 10px;
    }

    &.small-modal {
      padding: 20px;
      min-height: 200px;
      transform: translate(0%, 0%);
      -webkit-transform: translate(0%, 0%);
      -moz-transform: translate(0%, 0%);
      -ms-transform: translate(0%, 0%);
      -o-transform: translate(0%, 0%);
      h2 {
        padding: 0;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 20px;
      }

      .d-flex-model {
        gap: 20px;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  .extra-fee-inner {
    padding: 15px;
    .ef-d-flex {
      flex-wrap: wrap;
      .fee-details {
        width: 100%;
      }
    }
  }
}

@media (max-height: 767px) {
  .height-setting {
    max-height: 409px;
  }
  .modal-box {
    height: 558px;
  }
}

.confirmPopup {
  display: flex;
  align-items: center;
  justify-content: center;

  .close-btn {
    border: 0px;
    background: transparent;
    cursor: pointer;
  }

  .modal-box.small-modal {
    height: auto;
  }

  .spacerLR_model {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.confirmation-btn {
  &:hover {
    background: var(--bs-button-bg);
    border: 1px solid var(--bs-button-bg);
  }
}

.hideBox {
  display: none;
}

.infoPoup {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-box {
    min-height: 1px;
    height: auto;
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0%, 0%);
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    -o-transform: translate(0%, 0%);
  }

  .height-setting {
    overflow-y: auto !important;
    max-height: 540px;
    background: #fff;
    position: relative;
  }

  .spacerLR_model {
    padding-left: 15px;
    padding-right: 15px;
  }

  .etra-fee-title {
    padding-right: 40px;
  }

  @media (max-width: 991px) {
    .modal-box {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 767px) {
    .modal-box .close-btn {
      right: 15px;
      top: 23px;
    }
  }
}

.extra-fee-inner {
  .input-group {
    position: relative;
    display: flex;
    border-collapse: separate;
    width:85%
  }
  .input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
    vertical-align:middle;
    margin-left:10%;
}
.btn-default {
  background-color: transparent;
  border: none;
  
}
.input-group .form-control:not(:first-child):not(:last-child), 
.input-group-addon:not(:first-child):not(:last-child), 
.input-group-btn:not(:first-child):not(:last-child) {
  border-radius: 0;
  font-size: 18px;
  box-shadow: none;
}
input{
    border: 1px solid rgba(var(--black), 1);
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    padding: 8px 40px;
    border-radius: 2px;
    background: #fff;
    cursor: pointer;
}
.spinner-minus {
  background: url(../images/spinner-minus.png) no-repeat;
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-top: 65%;
}

.spinner-plus {
  background: url(../images/spinner-plus.png) no-repeat;
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-top: 65%;
}

}
