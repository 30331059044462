.gray-bg {
  background-color: rgba(var(--light-gray), 1);
  padding: 15px;
  margin-bottom: 15px;
  h2 {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 26px;
  }
  .d-flex {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    font-family: var(--font-ride);

    .form-check {
      width: 200px;

      label {
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        padding-left: 10px;
      }

      .form-check-input[type="checkbox"] {
        border-radius: 0;
        width: 18px;
        height: 18px;
      }
    }
  }
}
