.notfound {
    max-width: 700px;
    padding: 20px 0px;
    margin: 0px auto;
    .heading-h1-container {
        margin-top: 1rem;
        font-size: 3rem;
        line-height: 1.5;
    }
    .heading-h5-container {
        margin-top: 2rem;
        margin-bottom: 4rem;
        font-size: 1.4rem;
    }
}