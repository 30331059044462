.custon-table {
  thead tr {
    background-color: rgba(var(--light-gray), 1);
    border: 1px solid #fff;
  }

  th,
  td {
    vertical-align: middle;
    font-size: 14px;
    line-height: 20px;
    padding: 14px;
    .btn {
      background: transparent;
      padding: 0;
      color: rgba(var(--black), 0.5);
      border: none;
    }
  }
  th {
    padding: 16px;
  }
}

.counters {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;

  .small-cards {
    box-shadow: 0px 0px 9px rgba(var(--black), 0.1);
    width: calc(33.33% - 15px);
    padding: 30px;
    min-width: 210px;
    border-bottom: 5px solid rgba(var(--primary-color), 1);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-height: 180px;

    h2.title {
      color: rgba(var(--text-color), 1);
      font-size: 34px;
      line-height: 34px;
      margin: 10px 0;
    }

    p.subtitle {
      color: rgba(var(--text-color), 0.5);
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 0;
    }
  }
}
@media (max-width: 1199px) {
  .counters .small-cards {
    padding: 15px;
    h2.title {
      font-size: 24px;
      margin: 5px 0;
    }
  }
}
@media (max-width: 768px) {
  .counters .small-cards {
    width: calc(50% - 10px);
    min-width: auto;
  }
}

.main-padding {
  padding: 40px 0;
  font-family: var(--font-ride);
}

.d-flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;

  > section {
    width: calc(50% - 10px);
    margin-bottom: 30px;
  }
}

.sub-heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: rgba(var(--text-color), 1);
  position: relative;
  margin-bottom: 25px;

  &:before {
    content: "";
    position: absolute;
    height: 5px;
    width: 42px;
    height: 4px;
    bottom: -15px;
    background-color: rgba(var(--primary-color), 1);
  }
}

// graph
.graph {
  background-color: rgba(var(--light-gray), 1);
  padding: 30px 30px 0;

  select {
    background-color: #fff;
    border: none;
    font-size: 13px;
    min-width: 62px;
    height: 35px;
    margin-left: 10px;
    border-radius: 2px;
    text-align: center;

    &:focus {
      outline: none;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }
}

//user-profile

.user-profile {
  .d-flex-container {
    flex-wrap: wrap;
    margin-top: 40px;

    .user-icon {
      width: 100px;

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
    }

    .user-details {
      width: calc(100% - 120px);
      color: rgba(var(--text-color), 1);

      h3 {
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 20px;
      }

      .d-flex-container {
        margin-top: 0;
        justify-content: flex-start;

        .user-details-fields {
          margin-right: 30px;
          width: 180px;

          p {
            font-size: 14px;
            line-height: 21px;
            font-weight: 700;
            margin-bottom: 0;

            span {
              display: block;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
.dropdown-menu-box {
  position: relative;
}
.custon-dropdown-btn {
  width: 30px;
  height: 30px;
  background: url(../svg/three-dots-vertical.svg) no-repeat center !important;
  background-size: auto !important;
}
.custom-dropdown-menu {
  padding: 0;
  border: 1px solid rgba(var(--text-color), 0.2);
  border-radius: 4px;
  box-shadow: 0px 0px 9px rgba(var(--black), 0.1);
  display: none;
  position: absolute;
  background-color: #fff;
  right: 50px;
  z-index: 1;
  li {
    padding: 4px;
    list-style: none;
    border-bottom: 1px solid rgba(var(--text-color), 0.2);
  }
}
@media (max-width: 1199px) {
  .user-profile .d-flex-container .user-details .d-flex-container .user-details-fields {
    margin-right: 30px;
    width: 120px;
  }
}

@media (max-width: 767px) {
  .main-padding {
    padding: 15px 0;
  }

  .d-flex-container > section {
    width: 100%;
    margin-bottom: 15px;
  }

  .sub-heading {
    font-weight: 600;
    font-size: 18px;
  }

  .graph {
    padding: 15px;
  }

  .user-profile .d-flex-container .user-details h3 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 27px;
  }
}

@media (max-width: 576px) {
  .user-profile .d-flex-container .user-details .d-flex-container .user-details-fields {
    margin-right: 0px;
    width: 100%;
  }
}
